import React from "react";
import { IoMdClose } from "react-icons/io";

interface VideoModalProps {
  close: () => void;
  item: any;
}

const VideoModal: React.FC<VideoModalProps> = ({ close, item }) => {
  return (
    <div className="w-full rounded-[16px] overflow-hidden">
      {" "}
      <div className="flex w-full items-center justify-between py-[15px] px-[24px]  bg-[#031A6B]">
        <h3 className="font-medium text-[20px] text-white font-sansbold  opacity-70">
          {item.title}
        </h3>
        <div className="cursor-pointer" onClick={close}>
          <IoMdClose className="text-[25px] text-white" />
        </div>
      </div>
      <div className="w-full h-[450px]">
        <iframe
          width="100%"
          height="100%"
          src={item.video}
          title="YouTube video player"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        ></iframe>
      </div>
      <div
        className="flex w-full items-center justify-center cursor-pointer py-[15px] px-[24px] bg-[#031A6B]"
        onClick={close}
      >
        <h3 className="font-medium text-[16px] text-white font-sans opacity-70">
          Close
        </h3>
      </div>
    </div>
  );
};

export default VideoModal;
