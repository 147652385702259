import React from "react";
import PageLayout from "../layouts/PageLayout";
import Contact from "../components/home/Contact";
import App from "../components/home/App";
import Video from "../components/about/Video";
import Tech from "../components/about/Tech";
import AboutHero from "../components/about/AboutHero";
import Mission from "../components/about/Mission";

function About() {
  return (
    <PageLayout>
      <AboutHero />
      <Tech />
      <Video />
      <Mission />
      <App />
      <Contact />
    </PageLayout>
  );
}

export default About;
