import React from 'react'


interface AppButtonProps {
	icon?: any;
	label?: string;
  placeholder?: string;
  variant?: string;
}

export const AppButton: React.FC<AppButtonProps> = ({
	icon,
	label,
  placeholder,
  variant = "primary"
}) => {
  const Icon = icon;
  return (
    <div
      className={`${
        variant === "primary" ? `bg-[#EF767A]` : `bg-white`
      } rounded-[200px]  md:px-[1.5rem] px-[1rem] md:py-[1.125rem] py-[1rem] flex items-center justify-center cursor-pointer shadow-md`}
    >
      <div className="flex items-center ">
        <Icon className={`${variant === "primary"? `text-white` :  `text-black`} text-[2.5rem]`} />
        <div className="ml-3">
          <h3 className={`${variant === "primary" ? `text-white` : `text-black`} opacity-70 md:text-[0.813rem] text-[13px] font-medium font-sans`}>
            {label}
          </h3>
          <p className={`${variant === "primary" ? `text-white` : `text-black`} font-sansbold font-semibold md:text-[1.125rem] text-base leading-[1.25rem]`}>
            {placeholder}
          </p>
        </div>
      </div>
    </div>
  );
};
 