import phonesDesk from "../../assets/img/desk-img.webp"
import phonesMobile from "../../assets/img/mobile-img.webp";


function Tech() {
  return (
    <section className="parallax h-screen w-full bg-tech bg-center overflow-hidden">
      <div className="pt-[108px] w-full md:px-[96px] px-[16px]">
        <h3 className="font-medium font-sans md:text-[48px] text-[36px] leading-[120%] md:w-[824px] w-full text-[#334776]">
          We use cutting-edge technology and innovative teaching methods to make
          education accessible
        </h3>
        <div className="mt-[115px] md:h-[565px] h-[351px] md:w-[1217px] w-full mx-auto relative overflow-hidden">
          <img
            src={phonesDesk}
            alt="Three Screens"
            className="h-full w-full bg-center bg-cover pointer-events-none md:block hidden"
          />
          <img
            src={phonesMobile}
            alt="Three Screens"
            className="h-full w-full bg-center bg-cover pointer-events-none md:hidden block absolute "
          />
        </div>
      </div>
    </section>
  );
}

export default Tech;
