import React from 'react'
import PageLayout from '../layouts/PageLayout'
import Policy from '../components/privacy/Policy'

function Privacy() {
		return (
			<PageLayout>
				<Policy />
				</PageLayout>
		)
}

export default Privacy