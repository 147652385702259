import React from "react";
import { Link } from "react-router-dom";

function Policy() {
  return (
    <section className="parallax bg-features h-full ">
      <div className="w-full  mx-auto py-[100px] px-[96px]">
        <div className="md:mb-8   md:w-full   w-11/12 mx-auto flex h-full justify-center overflow-hidden flex-col items-start">
          <h3 className="text-left font-sansbold text-black text-[28px] leading-[36px] md:text-[48px] md:leading-[56px] font-extrabold">
            Privacy Policy
          </h3>
        </div>
        <div className="md:mt-14 mt-8  w-full">
          <div className="border-t border-[#031A6B] border-opacity-10 md:py-12 w-full py-8 col-span-4">
            <div className=" w-full">
              <div className="border-b border-[#031A6B] border-opacity-10 pb-6">
                <p className="py-4 text-[#334776] font-sansregular opacity-70  leading-[24px]">
                  Effective Date: 4th September, 2023
                </p>
                <p className="py-4 text-[#334776] font-sansregular opacity-70  leading-[24px]">
                  At Learn Lift, we are committed to protecting your privacy and
                  ensuring the security of your personal information. This
                  Privacy Policy outlines how we collect, use, disclose, and
                  protect your data when you use our personalized and
                  digitalized teaching services for primary, secondary, special
                  exams, and tertiary education. By using our platform, you
                  agree to the practices described in this policy. Please read
                  this Privacy Policy carefully and contact us if you have any
                  questions or concerns.
                </p>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-3xl text-[22px]">
                  1. Information We Collect
                </h3>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-2xl text-[18px]">
                  1.1 Personal Information:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70  leading-[24px]">
                  We may collect personal information from you when you sign up
                  for our services, including but not limited to your name,
                  email address, contact number, date of birth, and educational
                  background. This information is essential for us to create a
                  personalized learning experience tailored to your needs and
                  ensure effective communication with you.
                </p>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-2xl text-[18px]">
                  1.2 Academic Data:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  To provide our personalized teaching services, we may collect
                  information about your academic performance, progress, and
                  learning preferences. This data enables us to analyze your
                  strengths and weaknesses, track your improvements, and design
                  a curriculum that fosters your success.
                </p>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-2xl text-[18px]">
                  1.3 Payment Information:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  When you make transactions on our platform, we collect payment
                  information, such as credit card details or other financial
                  data, to process your payments securely. Please note that all
                  transactions are encrypted to protect your financial data.
                </p>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-2xl text-[18px]">
                  1.4 Cookies and Usage Data:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  We may use cookies and similar technologies to enhance your
                  user experience, remember your preferences, and analyze how
                  you interact with our website. This data helps us improve our
                  services and tailor content to better suit your interests and
                  needs.
                </p>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-3xl text-[22px]">
                  2. How We Use Your Information
                </h3>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-2xl text-[18px]">
                  2.1 Personalization of Teaching:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  The information we collect allows us to provide personalized
                  learning experiences that cater to your individual strengths
                  and weaknesses. By understanding your academic progress and
                  preferences, we can optimize the educational content and tools
                  to maximize your success.
                </p>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-2xl text-[18px]">
                  2.2 Communication:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  We may use your contact information to communicate with you
                  about your learning progress, upcoming classes, new features,
                  and updates related to our platform. Our aim is to keep you
                  informed and engaged throughout your learning journey.
                </p>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-2xl text-[18px]">
                  2.3 Improving Our Services:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  The data we gather helps us to analyze user behavior and
                  preferences, identify areas of improvement, and enhance the
                  overall functionality and content of our platform. We
                  constantly strive to optimize our services to meet the
                  evolving needs of our learners.
                </p>{" "}
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-2xl text-[18px]">
                  2.4 Payment Processing:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  Your payment information is used solely for processing
                  transactions related to our services. We do not store your
                  payment details beyond what is required for the specific
                  transaction.
                </p>
              </div>
              <div className="border-b border-[#031A6B] border-opacity-10 pt-8 pb-6">
                <h3 className="py-3 text-[#334776] font-sansregularbold font-extrabold md:text-3xl text-[22px]">
                  3. Data Security
                </h3>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-2xl text-[18px]">
                  3.1 Protecting Your Information:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  Learn Lift takes data security seriously and employs various
                  measures to safeguard your personal information. We use
                  industry-standard encryption protocols to protect data
                  transmission and implement strict access controls to ensure
                  that only authorized personnel have access to your data.
                </p>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-2xl text-[18px]">
                  3.2 Third-Party Services:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  In some cases, we may use third-party service providers to
                  support our platform's functionality and services. These
                  providers adhere to strict data security standards and are
                  bound by confidentiality agreements to protect your
                  information.
                </p>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-2xl text-[18px]">
                  3.3 User Responsibility:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  While we take all reasonable precautions to protect your data,
                  it is essential for you to maintain the security of your
                  account. Please ensure that your password is secure and not
                  shared with others. If you suspect any unauthorized access to
                  your account, please notify us immediately.
                </p>
                <h3 className="py-3 text-[#334776] font-sansregularbold font-extrabold md:text-3xl text-[22px]">
                  4. Non-Refund Policy
                </h3>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-2xl text-[18px]">
                  4.1 No Refunds:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  Learn Lift has a non-refund policy. Once you purchase our
                  services, the payment is non-refundable, regardless of whether
                  you complete the course or achieve the desired outcome. We
                  encourage you to carefully review our offerings before making
                  a purchase.
                </p>
                <h3 className="py-3 text-[#334776] font-sansregularbold font-extrabold md:text-3xl text-[22px]">
                  5. Limitation of Liability
                </h3>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-2xl text-[18px]">
                  5.1 Disclaimer:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  Learn Lift and its directors or staff shall not be held liable
                  for any challenges or difficulties encountered during the use
                  of our platform or services. While we make every effort to
                  provide high-quality educational content, we cannot guarantee
                  specific outcomes or results for individual learners.
                </p>
              </div>
              <div className="pt-8 pb-6">
                <h3 className="py-3 text-[#334776] font-sansregularbold font-extrabold md:text-3xl text-[22px]">
                  6. Testimonial Advertising
                </h3>{" "}
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-2xl text-[18px]">
                  6.1 Testimonial Usage:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  By using our platform, you grant Learn Lift the absolute
                  permission to advertise your examination success as
                  testimonies on all our platforms and social media handles.
                  Your success stories inspire and motivate other learners,
                  encouraging them to strive for excellence.
                </p>
                <h3 className="py-3 text-[#334776] font-sansregularbold font-extrabold md:text-3xl text-[22px]">
                  {" "}
                  7. Minors' Privacy
                </h3>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-2xl text-[18px]">
                  7.1 Parental Consent:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  We understand the importance of protecting the privacy of
                  minors. If you are under the age of 18, you must obtain
                  parental or legal guardian consent before using our platform
                  and providing any personal information to us.
                </p>
                <h3 className="py-3 text-[#334776] font-sansregularbold font-extrabold md:text-3xl text-[22px]">
                  8. Changes to the Privacy Policy
                </h3>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-2xl text-[18px]">
                  8.1 Policy Updates:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  This Privacy Policy may be updated from time to time to
                  reflect changes in our practices and services. We will notify
                  you of any significant changes, and the revised policy will be
                  effective immediately upon posting.
                </p>
                <h3 className="py-3 text-[#334776] font-sansregularbold font-extrabold md:text-3xl text-[22px]">
                  9. Boosting the Zeal of the Students
                </h3>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-2xl text-[18px]">
                  9.1 Empowering Learners:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  At Learn Lift, our mission is to empower learners across
                  Africa to reach their full potential. We are dedicated to
                  creating an inclusive and supportive learning environment that
                  fosters curiosity, critical thinking, and a passion for
                  knowledge.
                </p>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-2xl text-[18px]">
                  9.2 Tailored Learning Experience:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  Through our personalized teaching approach, we aim to provide
                  each student with a unique and engaging learning experience.
                  By understanding your strengths and areas for improvement, we
                  can curate educational content that challenges and uplifts
                  you.
                </p>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-2xl text-[18px]">
                  9.3 Success-Driven Curriculum:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  Our curriculum is designed with a focus on achieving success
                  in your academic pursuits. We provide comprehensive resources
                  and tools that align with the latest educational standards to
                  help you excel in your studies and examinations.
                </p>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-2xl text-[18px]">
                  9.4 Continuous Improvement:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  We are committed to continuous improvement in our services.
                  Your feedback and suggestions are invaluable to us, as they
                  help us refine our platform and cater to your evolving needs
                  effectively.
                </p>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  In conclusion, Learn Lift is committed to protecting your
                  privacy, providing a seamless learning experience, and
                  supporting your journey towards academic success. We value
                  your trust and remain dedicated to delivering high-quality
                  education to learners across Africa. If you have any questions
                  or concerns regarding our Privacy Policy or services, please
                  don't hesitate to contact us.
                </p>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-2xl text-[18px]">
                  Learn Lift – Lifting Learners!
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Policy;
