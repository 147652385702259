import React, { useState } from "react";
import { FiBookOpen } from "react-icons/fi";
import { GoVideo } from "react-icons/go";
import { BiMath } from "react-icons/bi";
import { HiOutlineLightningBolt } from "react-icons/hi";
import { RiQuestionnaireLine } from "react-icons/ri";
import feat1 from "../../assets/img/feature-1.webp";
import feat2 from "../../assets/img/feature-2.webp";
import feat3 from "../../assets/img/feature-3.webp";
import feat4 from "../../assets/img/feature-4.webp";
import feat5 from "../../assets/img/feature-5.webp";

const data = [
  {
    id: 1,
    image: feat1,
    title: "Self study classes",
    icon: FiBookOpen,
  },
  {
    id: 2,
    image: feat2,
    title: "Live classes",
    icon: GoVideo,
  },
  {
    id: 3,
    image: feat3,
    title: "Test and quizzes",
    icon: BiMath,
  },
  {
    id: 4,
    image: feat4,
    title: "Progress tracker",
    icon: HiOutlineLightningBolt,
  },
  {
    id: 5,
    image: feat5,
    title: "Assignments",
    icon: RiQuestionnaireLine,
  },
];

interface DataItem {
  id: number;
  image: any;
  title: string;
  icon: any;
}

const Features = () => {
  const [selectedTab, setSelectedTab] = useState<number>(data[0].id);

  const handleTabClick = (id: number) => {
    setSelectedTab(id);
  };

  return (
    <section className="parallax bg-features h-full ">
      <div className="w-full md:py-[217px] py-[89px] mx-auto md:px-[183px] px-[16px] flex h-full items-center justify-center overflow-hidden">
        <div className="w-full h-[544px] flex flex-col-reverse md:flex-row items-center justify-center">
          <div className="md:h-full h-[300px] md:w-[777px] w-full rounded-tr-[57px] rounded-tl-[14px] rounded-bl-[57px] rounded-br-[14px]">
            <img
              src={data?.find((item) => item?.id === selectedTab)?.image || ""}
              alt={data?.find((item) => item?.id === selectedTab)?.title || ""}
              className="h-full w-full bg-fit pointer-events-none"
            />
          </div>
          <div className="md:w-[233px] md:ml-[64px] flex flex-row md:flex-col md:gap-6 gap-0 w-full md:mb-0 mb-[29px] scroll-container">
            {data.map((item: DataItem) => {
              const Icon = item.icon;
              return (
           
                <div
                  className={`p-[16px] rounded-[200px]  w-full flex min-w-[250px] items-center justify-center md:mb-[14px] my-3 cursor-pointer mr-8 ${
                    selectedTab === item.id ? `bg-[#EF767A]` : `bg-[#BCD1F3]`
                  }`}
                  key={item.id}
                  onClick={() => handleTabClick(item.id)}
                >
                  <div className="flex items-center ">
                    <Icon
                      className={`text-[25px] ${
                        selectedTab === item.id
                          ? `text-white`
                          : `text-[#334776]`
                      }`}
                    />
                    <h3
                      className={` text-[18px] font-medium font-sans ml-4 ${
                        selectedTab === item.id
                          ? `text-white`
                          : `text-[#334776]`
                      }`}
                    >
                      {item.title}
                    </h3>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Features;
