import React from "react";
import { MdOutlineAlternateEmail } from "react-icons/md";
import {
  RiTiktokLine,
  RiInstagramLine,
  RiTwitterLine,
  RiLinkedinLine,
} from "react-icons/ri";
import { FaFacebookF } from "react-icons/fa";
import { Link } from "react-router-dom";

const items = [
  {
    id: 1,
    title: "Email",
    icon: MdOutlineAlternateEmail,
    url: "mailto:hello@learnlift.org",
  },
  {
    id: 2,
    title: "TikTok",
    icon: RiTiktokLine,
    url: "https://www.tiktok.com/@learnliftapp?_t=8f5bQoNoSKU&_r=1",
  },
  {
    id: 3,
    title: "Facebook",
    icon: FaFacebookF,
    url: "https://www.facebook.com/profile.php?id=61550724000724&mibextid=b06tZ0",
  },
  {
    id: 4,
    title: "Instagram",
    icon: RiInstagramLine,
    url: "https://instagram.com/learnliftapp?igshid=NTc4MTIwNjQ2YQ==",
  },
  {
    id: 5,
    title: "Twitter",
    icon: RiTwitterLine,
    url: "https://twitter.com/LearnLiftapp?t=dgX4489coXAT2WilW_-HwA&s=09",
  },
  {
    id: 6,
    title: "LinkedIn",
    icon: RiLinkedinLine,
    url: "https://www.linkedin.com/in/learn-liftapp-916b72289",
  },
];

interface DataItem {
  id: number;
  url: any;
  title: string;
  icon: any;
}

function Contact() {
  return (
    <section className="parallax bg-contact h-full bg-center">
      <div className="w-full mx-auto md:pl-[96px] pl-0 flex flex-col md:flex-row h-full overflow-hidden">
        <div className="md:w-[50%] w-full md:pt-[150px] pt-[129px] md:pb-0 pb-[129px] px-[16px] md:px-0">
          <h3 className=" leading-[120%] font-medium md:text-[48px] text-[36px]">
            <span className="font-sansbold text-[#120D31] font-semibold">
              Get in touch
            </span>
            <br />
            <span className="font-sans font-medium text-[#334776]">
              We respond fast
            </span>
          </h3>
          <p className="font-sansregular font-normal text-[#334776] md:text-[28px] text-[18px] md:mt-[48px] mt-[25px]">
            Our support team is always available
          </p>
        </div>
        <div className="md:w-[50%] w-full bg-[#DFFDFF] md:px-[102px] px-[16px] md:py-[91px] py-[69px] grid grid-cols-2 md:gap-[28px] gap-[24px] h-full place-content-center mx-auto">
          {items.map((item: DataItem, index) => {
            const Icon = item?.icon;
            return (
              <Link
                to={item.url}
                key={index}
                className="w-full rounded-[16px] border border-[#3B82F6] md:px-[48px] px-[30px] cursor-pointer flex items-start flex-col h-full max-h-[223px] md:hover:shadow-md md:hover:transition md:hover:delay-50 md:hover:ease-in"
              >
                <div className="py-[48px]">
                  <div className="icon-container">
                    <div className="square bg-[#031A6B] w-[70px]  flex items-center justify-center md:h-[70px] h-[60px] absolute rounded-[8px]">
                      <Icon className="text-white md:text-[40px] text-[30px]" />
                    </div>
                    <div className="rotated-square rounded-[8px]"></div>
                  </div>
                  <h3 className="text-[#334776] font-normal font-sansregular md:text-[28px] text-[18px] pt-[20px]">
                    {item.title}
                  </h3>
                </div>
              </Link>
            );
          })}
        </div>
      </div>
    </section>
  );
}

export default Contact;
