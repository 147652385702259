import React from 'react'
import PageLayout from '../layouts/PageLayout'
import Terms from '../components/terms/Terms'

function TermsAndCondition() {
		return (
			<PageLayout>
				<Terms />
		</PageLayout>
		)
}

export default TermsAndCondition