import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages";
import About from "./pages/about-us";
import Privacy from "./pages/privacy-policy";
import TermsAndCondition from "./pages/terms";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about-us" element={<About />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-and-conditions" element={<TermsAndCondition />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
