import React from "react";
import { Link } from "react-router-dom";

function Terms() {
  return (
    <section className="parallax bg-features h-full ">
      <div className="w-full  mx-auto py-[100px] px-[96px]">
        <div className="md:mb-8   md:w-full   w-11/12 mx-auto flex h-full justify-center overflow-hidden flex-col items-start">
          <h3 className="text-left font-sansbold text-black text-[28px] leading-[36px] md:text-[48px] md:leading-[56px] font-extrabold">
            Terms And Conditions
          </h3>
        </div>
        <div className="md:mt-14 mt-8  w-full">
          <div className="border-t border-[#031A6B] border-opacity-10 md:py-12 w-full py-8 col-span-4">
            <div className=" w-full">
              <div className="border-b border-[#031A6B] border-opacity-10 pb-6">
                <p className="py-4 text-[#334776] font-sansregular opacity-70  leading-[24px]">
                  Welcome to Learn Lift, an innovative and curriculum-focused
                  EdTech company dedicated to providing personalized and
                  digitalized teaching services to learners of all levels, from
                  primary to tertiary education. By using our services, you
                  agree to be bound by the following terms and conditions.
                  Please read them carefully.
                </p>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-3xl text-[22px]">
                  1. Scope of Services:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70  leading-[24px]">
                  Learn Lift offers personalized and digitalized teaching
                  services for the following educational levels:
                  <ul>
                    <li>- Primary Education (Grades 1-6)</li>
                    <li> - Secondary Education (Junior and Senior)</li>
                    <li>
                      {" "}
                      - Special Exams (WAEC, NECO, GCE, JAMB/UTME, POST-UTME,
                      School of Nursing, NDA, Police Academy, etc.)
                    </li>
                    <li>
                      - Tertiary Education (Year 1 - Final year students across
                      all departments and faculties) We are committed to
                      excellence, aiming to elevate learners' academic
                      performance and success through our high-quality
                      educational programs.
                    </li>
                  </ul>{" "}
                </p>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-3xl text-[22px]">
                  2. Curriculum Focused and Success Driven:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  At Learn Lift, we place a strong emphasis on following
                  established curricula and ensuring our content aligns with
                  educational standards. Our primary goal is to empower learners
                  to achieve academic excellence through our success-driven
                  approach.
                </p>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-3xl text-[22px]">
                  3. Slogan: LIFTING LEARNERS
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  Our slogan, "LIFTING LEARNERS," represents our unwavering
                  commitment to uplifting and empowering our students to reach
                  their full potential.
                </p>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-3xl text-[22px]">
                  4. No Refunds Policy:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  Please note that Learn Lift does not offer refunds for any of
                  its services. Once you subscribe to our programs or purchase
                  our courses, you agree to adhere to this policy. We believe in
                  the value of our services and trust that our students will
                  experience significant academic progress and achievement.
                </p>
                <h3 className="md:py-3 text-[#334776] font-sansregularbold font-extrabold md:text-3xl text-[22px]">
                  5. Limited Liability:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  While Learn Lift strives to provide the best possible learning
                  experience, we cannot guarantee uninterrupted access to our
                  platform or the complete absence of errors or technical
                  issues. By using our services, you acknowledge and agree that
                  Learn Lift and its directors or staff cannot be held
                  responsible or liable for any challenges you encounter while
                  using our platform. However, we are committed to resolving
                  issues promptly and ensuring a seamless learning experience.
                </p>
              </div>
              <div className="border-b border-[#031A6B] border-opacity-10 pt-8 pb-6">
                <h3 className="py-3 text-[#334776] font-sansregularbold font-extrabold md:text-3xl text-[22px]">
                  6. Consent for Testimonials:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  As part of our commitment to celebrating the success of our
                  students and motivating others, we request your absolute
                  permission, as well as the consent of your parents or
                  guardians, to advertise your examination success as
                  testimonials on all our platforms and social media handles.
                  These testimonials will be used solely for promotional
                  purposes to inspire and encourage other learners.
                </p>
                <h3 className="py-3 text-[#334776] font-sansregularbold font-extrabold md:text-3xl text-[22px]">
                  7. Confidentiality and Data Protection:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  Learn Lift values the privacy and confidentiality of its
                  users. We have implemented robust security measures to
                  safeguard your personal information and ensure compliance with
                  data protection laws. By using our services, you agree to our
                  Privacy Policy, which governs the collection, use, and
                  disclosure of your personal data.
                </p>
                <h3 className="py-3 text-[#334776] font-sansregularbold font-extrabold md:text-3xl text-[22px]">
                  8. Prohibited Activities:
                </h3>
                <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">
                  While using Learn Lift's platform, you must not engage in any
                  of the following activities:
                  <ul>
                    <li>- Violating any applicable laws or regulations.</li>
                    <li>
                      {" "}
                      - Infringing upon the intellectual property rights of
                      Learn Lift or any third party.
                    </li>
                    <li>
                      - Attempting to gain unauthorized access to our systems or
                      interfering with the proper functioning of our platform.
                    </li>
                    <li>
                      - Engaging in any form of abusive, offensive, or harmful
                      behavior towards other users or Learn Lift staff.
                    </li>
                  </ul>{" "}
                </p>
              </div>
              <div className="pt-8 pb-6">
                <h3 className="py-3 text-[#334776] font-sansregularbold font-extrabold md:text-3xl text-[22px]">
                  9. Intellectual Property Rights:
                </h3>{" "}
                 <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">All content provided by Learn Lift, including but not limited to
                text, graphics, logos, images, videos, and course materials, is
                the intellectual property of Learn Lift and protected by
                copyright laws. You are granted a non-exclusive,
                non-transferable license to access and use the content solely
                for educational purposes. Reproduction, distribution, or
                unauthorized use of any content is strictly prohibited.</p>
                <h3 className="py-3 text-[#334776] font-sansregularbold font-extrabold md:text-3xl text-[22px]"> 10.
                Third-Party Links:</h3>
                 <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]"> Learn Lift's platform may contain links to
                third-party websites or resources for your convenience. However,
                we do not endorse or control these external sites, and we are
                not responsible for their content or practices. Your use of
                third-party links is at your own risk.</p>
                 <h3 className="py-3 text-[#334776] font-sansregularbold font-extrabold md:text-3xl text-[22px]">11. Amendments to Terms
                  and Conditions:</h3>
                 <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">Learn Lift reserves the right to update or
                modify these terms and conditions at any time. Any changes will
                be communicated to users through the platform or via email.
                Continued use of our services after any modifications implies
                your acceptance of the updated terms.</p>
                 <h3 className="py-3 text-[#334776] font-sansregularbold font-extrabold md:text-3xl text-[22px]">12. Termination of
                Services:</h3> 
                 <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">Learn Lift reserves the right to suspend or terminate
                your access to the platform, temporarily or permanently, for any
                violation of these terms and conditions or for any other reason
                deemed necessary.</p>
                <h3 className="py-3 text-[#334776] font-sansregularbold font-extrabold md:text-3xl text-[22px]">13. Governing Law and Jurisdiction:</h3>
                 <p className="py-4 text-[#334776] font-sansregular opacity-70 leading-[24px]">These
                terms and conditions are governed by the laws of Nigeria, and
                any dispute arising from their interpretation or implementation
                shall be subject to the exclusive jurisdiction of the courts of
                Nigeria. By using Learn Lift's services, you acknowledge that
                you have read, understood, and agreed to abide by these terms
                and conditions. We are excited to have you join our educational
                community and look forward to supporting your academic journey
                and success with Learn Lift's personalized and digitalized
                teaching programs. Remember, at Learn Lift, we are committed to
                LIFTING LEARNERS and empowering you to achieve your educational
                goals.</p>
              
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Terms;
