import React, { useState, useEffect } from "react";
import primary from "../../assets/img/primary.svg";
import secondary from "../../assets/img/secondary.svg";
import tertiary from "../../assets/img/tertiary.svg";

const data = [
  {
    id: 0,
    image: primary,
    title: "Primary",
    desc: "Engaging learning encompassing Mathematics, English, and Sciences to provide an ideal foundation for academic excellence.",
  },
  {
    id: 1,
    image: secondary,
    title: "Secondary",
    desc: "Gain proficiency through straightforward lessons covering Mathematics, English, Sciences, Arts & Commercials.",
  },
  {
    id: 2,
    image: tertiary,
    title: "Tertiary",
    desc: "Become a first-class student through straightforward lessons covering Medical classes, Engineering, Biological and Natural sciences, Law and Art courses, Commercial and Business school.",
  },
];

interface DataItem {
  id: number;
  image: any;
  title: string;
  desc: string;
}

const Offer = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      // Update the state to select the next ID
      setCurrentIndex((prevIndex) => (prevIndex + 1) % data.length);
    }, 5000); // 20 seconds

    // Clean up the interval when the component unmounts
    return () => clearInterval(interval);
  }, []);
  return (
    <section className="parallax bg-offer h-full">
      <div className="w-full mx-auto md:pl-[96px] pl-0 flex flex-col md:flex-row h-full overflow-hidden">
        <div className="md:w-[50%] w-full md:pt-[122px] pt-[89px] md:px-0 px-[16px]">
          <h3 className="md:w-[588px] leading-[120%] font-medium md:text-[48px] text-[36px] text-white font-sans">
            We offer lessons that cover different educational goals
          </h3>
        </div>
        <div className="md:w-[50%] w-full md:mt-0 mt-[73px] bg-[#2E4183] md:pl-[76px] md:pr-[102px] px-[16px] md:py-[42px] py-[99px] flex items-center justify-center">
          <div className="h-full w-full grid grid-rows-3 md:gap-[16px] gap-[37px]">
            {data.map((item: DataItem, index) => (
              <div
                key={index}
                onClick={() => setCurrentIndex(item.id)}
                className={`h-full md:p-[20px] p-[32px] w-full rounded-tr-[40px] rounded-bl-[40px] rounded-tl-[10px] cursor-pointer rounded-br-[10px]  ${
                  currentIndex === item.id
                    ? `border border-[#EF767A] bg-[#2E4183]`
                    : `bg-[#031A6B]`
                }`}
              >
                <div className="h-full w-full flex flex-col md:flex-row gap-[24px]">
                 <img
                    src={item.image}
                    alt={item.title}
                    className="md:w-[40%] w-full h-[175px] md:h-full rounded-[14px] overflow-hidden bg-cover bg-center pointer-events-none"
                  />
              
                  <div className="md:w-[60%] w-full h-full ">
                    <h3 className="text-[24px] text-white leading-[150%] font-medium font-sans">
                      {item.title}
                    </h3>
                    <p className="text-base text-white leading-[150%] font-normal font-sansregular pt-[10px]">
                      {item.desc}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Offer;
