import hero1 from "../../assets/img/hero-1.webp";
import hero2 from "../../assets/img/hero-2.webp";
import hero3 from "../../assets/img/hero-3.webp";
import hero4 from "../../assets/img/hero-4.webp";
import prop from "../../assets/img/prop.svg"
import heroMobile from "../../assets/img/hero-mobile.webp"



interface DataItem {
  id: number;
  image: any;
  color: any;
}



function AboutHero() {


  return (
    <section className="h-full md:bg-aboutHero bg-mobileHero w-full md:py-[103px] py-[69px] parallax">
      <div className=" w-full md:px-[96px] px-[16px]">
        <div
          className="flex items-center justify-center my-[40px] md:w-[168px] md:h-[78px] h-[47px] w-[108px]"
          style={{
            backgroundImage: `url(${prop})`,
            backgroundSize: "contain",
            backgroundPosition: "center",
            backgroundRepeat: "no-repeat",
          }}
        >
          <h3 className="text-[#EF767A] md:text-[28px] text-[18px] font-medium font-sans text-center">
            About Us
          </h3>
        </div>
        <h3 className="font-medium font-sans md:text-[48px] text-[36px] leading-[120%] md:w-[1013px] w-full text-white">
          We ignite the passion for learning in students and empowers them to
          surpass their limits.
        </h3>

        <div className="content-wrapper md:flex hidden">
          <div className="img-wrapper float-up-animation">
            <img src={hero1} alt="Pexels" />
          </div>
          <div className="img-wrapper float-down-animation">
            <img src={hero2} alt="Pexels" />
          </div>
          <div className="img-wrapper float-up-animation">
            <img src={hero3} alt="Pexels" />
          </div>
          <div className="img-wrapper float-down-animation">
            <img src={hero4} alt="Pexels" />
          </div>
        </div>
        <div className="h-[25rem] w-full md:mt-0 mt-[89px] md:mb-0 mb-[150px] md:hidden block">
          <img src={heroMobile} alt="Hero" className="h-full w-full bg-center bg-contain" />
        </div>
      </div>
    </section>
  );
}

export default AboutHero;
