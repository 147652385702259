import React, {useState} from "react";
import logo from "../../assets/img/logo.svg";
import { FaGlobeAfrica, FaAngleDown } from "react-icons/fa";
import {HiMenuAlt4} from "react-icons/hi"
import { RiCloseFill } from "react-icons/ri";
import { Link, useLocation } from "react-router-dom";

const Header = () => {
  //assigning location variable
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;
  const [open, setOpen] = useState(false)

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split("/");
  return (
    <>
      <div className="w-full bg-[#031A6B] py-6">
        <div className="flex items-center w-full justify-between md:px-[96px] px-[16px] mx-auto">
          <Link to="/" className="flex items-center cursor-pointer">
            <img
              src={logo}
              className="w-[42px] h-[47px] pointer-events-none"
              alt="Logo"
            />

            <h3 className="text-white md:text-[20px] text-[18px] font-semibold font-sansbold md:ml-5 ml-3">
              Learn Lift
            </h3>
          </Link>
          <div
            className="h-[50px] w-[50px] rounded-full bg-black/30 md:hidden flex items-center justify-center cursor-pointer"
            onClick={() => setOpen(!open)}
          >
            {open ? (
              <RiCloseFill className="text-white text-[30px]" />
            ) : (
              <HiMenuAlt4 className="text-white text-[30px]" />
            )}
          </div>
          <div className="md:flex hidden items-center gap-[58px]">
            <Link to="https://blog.learnlift.org">
              <h3
                className={` text-[18px] font-medium font-sans ${
                  splitLocation[1] === "about-us"
                    ? `text-[#EF767A]`
                    : `text-white`
                }`}
              >
                Blog
              </h3>
            </Link>{" "}
            <Link to="/about-us">
              <h3
                className={` text-[18px] font-medium font-sans ${
                  splitLocation[1] === "about-us"
                    ? `text-[#EF767A]`
                    : `text-white`
                }`}
              >
                About Learnlift
              </h3>
            </Link>
            <div className="rounded-[200px] button-top p-[14px] flex items-center justify-between cursor-pointer">
              <div className="flex items-center">
                <FaGlobeAfrica className="text-white text-[20px]" />
                <h3 className="text-white text-base font-medium font-sans ml-4 mr-[28px]">
                  English
                </h3>
              </div>
              <FaAngleDown className="text-white text-[20px]" />
            </div>
          </div>
        </div>
      </div>
      {open && (
        <div className="w-full bg-[#031A6B] h-screen px-[16px] py-[80px]">
          <div className="flex flex-col w-full items-center gap-[58px]">
            <Link to="/about-us">
              <h3
                className={` text-[18px] font-medium font-sans ${
                  splitLocation[1] === "about-us"
                    ? `text-[#EF767A]`
                    : `text-white`
                }`}
              >
                About Learnlift
              </h3>
            </Link>
            <div className="rounded-[200px] button-top p-[14px] flex items-center justify-between cursor-pointer w-[250px]">
              <div className="flex items-center">
                <FaGlobeAfrica className="text-white text-[20px]" />
                <h3 className="text-white text-base font-medium font-sans ml-4 mr-[28px]">
                  English
                </h3>
              </div>
              <FaAngleDown className="text-white text-[20px]" />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
