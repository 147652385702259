import React from "react";
import { AppButton } from "../common/Buttons";
import { FaGooglePlay, FaApple } from "react-icons/fa";
import Qrcode from "../common/Qrcode";

const App = () => {
  return (
    <section className="parallax md:bg-app bg-appMobile h-screen bg-center">
      <div className="w-full pt-[90px] pb-[133px] mx-auto md:px-[6rem] px-[16px] flex flex-col justify-between h-full overflow-hidden">
        <div className="w-full flex flex-col md:flex-row items-center justify-between">
          <div className="">
            <h3 className="md:w-[621px] w-full text-white font-sans md:text-[48px] text-[36px] font-medium leading-[120%]">
              Everything you need to excel in school all in one app
            </h3>
          </div>

          <div className="flex items-center md:gap-6  gap-4 md:mt-0  mt-[48px]">
            <AppButton
              icon={FaGooglePlay}
              label="Get it on"
              placeholder="Google Play"
              variant="secondary"
            />
            <AppButton
              icon={FaApple}
              label="Download on the"
              placeholder="App store"
              variant="secondary"
            />
          </div>
        </div>
        <div className="w-full md:flex hidden items-start justify-start">
          <Qrcode />
        </div>
      </div>
    </section>
  );
};

export default App;
