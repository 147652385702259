import React from "react";
import phone from "../../assets/img/hero-phone.webp";
import iphone from "../../assets/img/iphone.webp";
import { FaGooglePlay, FaApple } from "react-icons/fa";
import { AppButton } from "../common/Buttons";

const Hero = () => {
  return (
    <section className="parallax bg-home-hero md:h-screen  h-full">
      <div className="w-full md:pt-[176px] pt-[99px] mx-auto md:px-[96px] px-[16px] flex flex-col md:flex-row h-full overflow-hidden">
        <div className="md:w-[60%] w-full h-full">
          <div>
            <h3 className="font-sansbold md:text-[4.5rem] text-[44px] leading-[120%] text-white md:w-[39.1rem] font-semibold">
              Reach your educational goal with Learn Lift
            </h3>
            <p className="text-[#F4FAFF] md:text-[18px] font-normal text-base font-sansregular md:w-[33.3rem] md:py-[3rem] py-[2rem] leading-[150%]">
              Our app offers a wide variety of courses, from math and science to
              history and literature. With our interactive lessons and engaging
              content, you’ll be prepared for the future in to time.
            </p>
            <div className="flex items-center md:gap-6 gap-4">
              <AppButton
                icon={FaGooglePlay}
                label="Get it on"
                placeholder="Google Play"
              />
              <AppButton
                icon={FaApple}
                label="Download on the"
                placeholder="App store"
              />
            </div>
          </div>
        </div>
        <div className="md:w-[40%] w-full h-full md:mt-0 mt-[80px]">
          <div className="md:w-[468px] w-[275px] md:h-[973px] h-[571px] mx-auto md:mb-0 mb-[89px]">
            <img
              src={phone}
              alt="Phone"
              className=" bg-center md:block hidden pointer-events-none"
            />
            <img
              src={iphone}
              alt="Phone"
              className=" bg-center block md:hidden pointer-events-none"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
