import React from "react";
import { Link } from "react-router-dom";
import Qrcode from "./Qrcode";
import { AppButton } from "./Buttons";
import { FaGooglePlay, FaApple } from "react-icons/fa";
import { HiOutlineLocationMarker } from "react-icons/hi";
import { FiPhoneCall } from "react-icons/fi";
import logo from "../../assets/img/footer-logo.svg";

function Footer() {
  return (
    <section className="w-full bg-[#031A6B]">
      <div className="flex flex-col items-center w-full justify-between md:px-[96px] px-[16px] mx-auto md:pt-[97px] pb-[58px] pt-[77px]">
        <div className="flex flex-col md:flex-row w-full h-full">
          <div className="md:w-[40%] w-full">
            <img
              src={logo}
              alt="Footer"
              className="h-[150px] w-[106px] pointer-events-none"
            />
            <p className="font-normal md:text-base text-[14px] leading-[24px] md:w-[400px] w-full text-white font-sansregular pt-[8px]">
              LEARN LIFT harnesses the expertise of top-tier educators,
              cutting-edge multimedia resources, and advanced technological
              tools to craft superior, cost-effective, and easily reachable
              learning experiences for students.
            </p>
            <ul className="mt-[32px]">
              <li className="mb-[16px]">
                <Link
                  to="tel:07030693452"
                  className="md:text-[16px] text-[14px] font-sansregular  text-white font-normal flex items-center gap-5"
                >
                  <FiPhoneCall className="text-white text-[22px]" />
                  0703 069 3452
                </Link>
              </li>
              <li className="mb-[16px]">
                <Link
                  to="#"
                  className="md:text-[16px] text-[14px]  font-sansregular  text-white font-normal flex items-center gap-5"
                >
                  <HiOutlineLocationMarker className="text-white text-[22px]" />{" "}
                  Calabar, Nigeria
                </Link>
              </li>
            </ul>
          </div>
          <div className="md:w-[60%] w-full md:grid md:grid-cols-3 flex flex-col gap-[70px] ">
            <div className="flex flex-col items-start justify-start w-full md:mt-0 mt-[71px]">
              <h3 className="font-medium md:text-[20px] font-sans text-base  text-white">
                Quick links
              </h3>
              <ul className="mt-[32px]">
                <li className="mb-[16px]">
                  <Link
                    to="https://blog.learnlift.org"
                    className="md:text-[16px] text-[14px] font-sansregular  text-white font-normal "
                  >
                    Blog
                  </Link>
                </li>
                <li className="mb-[16px]">
                  <Link
                    to="/about-us"
                    className="md:text-[16px] text-[14px] font-sansregular  text-white font-normal "
                  >
                    About Learn lift
                  </Link>
                </li>
                <li className="mb-[16px]">
                  <Link
                    to="/privacy-policy"
                    className="md:text-[16px] text-[14px] font-sansregular  text-white font-normal "
                  >
                    Privacy Policy
                  </Link>
                </li>
                <li className="mb-[16px]">
                  <Link
                    to="/terms-and-conditions"
                    className="md:text-[16px] text-[14px] font-sansregular  text-white font-normal "
                  >
                    Terms and conditions
                  </Link>
                </li>
              </ul>
            </div>
            <div className="w-full hidden md:block">
              <Qrcode variant="secondary" />
            </div>
            <div className="flex flex-col items-start justify-start w-full">
              <h3 className="font-medium text-[14px] md:text-[20px] font-sans  text-white">
                Download App on
              </h3>
              <div className="flex flex-col items-center gap-y-6 mt-[36px]">
                <AppButton
                  icon={FaGooglePlay}
                  label="Get it on"
                  placeholder="Google Play"
                />
                <AppButton
                  icon={FaApple}
                  label="Download on the"
                  placeholder="App store"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center md:mt-[69px] mt-[100px] w-full">
          <h3 className="md:text-base text-[14px] text-white opacity-70 font-sansregular ">
            Copyright © Learn Lift{" "}
          </h3>
          <ul className="items-center flex w-full">
            <li
              className={`md:mx-[32px] mx-[16px] py-3 cursor-pointer font-normal text-white`}
            >
              <Link
                to="#"
                className="md:text-base text-[14px] font-sansregular font-normal"
              >
                CAC Approved
              </Link>
            </li>
            <li
              className={`md:mx-[32px] mx-[16px] py-3 cursor-pointer font-normal text-white`}
            >
              <Link
                to="#"
                className="md:text-base text-[14px] font-sansregular font-normal"
              >
                Legal
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Footer;
