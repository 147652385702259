import React, { useState } from "react";
import Modal from "../common/Modal";
import VideoModal from "./VideoModal";

const videos = [
  {
    id: 1,
    title: "Heart Structure - Cardiovascular System Physiology.",
    video: "https://youtube.com/embed/pTbBkiEHues",
  },
  {
    id: 2,
    title: "Cell Therory Lesson 1 (Cell Biology)",
    video: "https://youtube.com/embed/8zrFY4X8U-8",
  },
  {
    id: 3,
    title: "Mastering 4-Digit Addition",
    video: "https://youtube.com/embed/wJRuvHLKNjM",
  },
];

interface VideoProps {
  onClick?: () => void;
}

interface DataItem {
  id: number;
  title: string;
  video: any;
}

interface VideoItemProps {
  onClick: () => void;
  item: any;
}

const Video: React.FC<VideoProps> = ({ onClick }) => {
  const [active, setActive] = useState(false);
  const [item, setItem] = useState("");

  const toggleTab = (item: any): void => {
    setActive(true);
    setItem(item);
  };

  const VideoItem: React.FC<VideoItemProps> = ({ item, onClick }) => {
    return (
      <div
        className="w-full flex flex-col items-start justify-start"
        onClick={onClick}
      >
        <div className="h-[377px] cursor-pointer w-full bg-videoCard bg-center bg-contain bg-no-repeat">
        </div>
        <div className="mt-[32px]">
          <h3 className="md:text-[20px] text-[18px] font-semibold font-sansbold text-white pb-[2px] leading-[26px] capitalize">
            {item.title}
          </h3>
          {/* <p className="font-normal md:text-[18px] text-[16px] text-white font-sansregular">
            {item.title}
          </p> */}
        </div>
      </div>
    );
  };
  return (
    <>
      <section className="parallax bg-[#3B82F6] md:py-[202px] py-[140px] parallax">
        <div className="w-full mx-auto md:px-[96px] px-[16px] h-full overflow-hidden flex flex-col items-center justify-center">
          <h3 className="text-white md:text-[48px] text-[36px] font-medium font-sans leading-[120%] md:w-[742px] w-full text-center">
            Vast library of video lessons, quizzes and exams
          </h3>
          <div className="mt-[48px] w-full md:grid md:grid-cols-3 flex flex-col md:flex-row mx-auto gap-[54px]">
            {videos.map((item: DataItem, index: number) => {
              return (
                <VideoItem
                  key={index}
                  item={item}
                  onClick={() => toggleTab(item)}
                />
              );
            })}
          </div>
        </div>
      </section>

      {active && (
        <Modal
          isOpen={active}
          onClose={() => setActive(false)}>
          <VideoModal
            item={item}
            close={() => setActive(false)} />
        </Modal>
      )}
    </>
  );
};

export default Video;
