import React from "react";
import Hero from "../components/home/Hero";
import Features from "../components/home/Features";
import Offer from "../components/home/Offer";
import Pricing from "../components/home/Pricing";
import App from "../components/home/App";
import Contact from "../components/home/Contact";
import PageLayout from "../layouts/PageLayout";

const Home = () => {
  return (
    <PageLayout>
      <Hero />
      <Features />
      <Offer />
      <Pricing />
      <App />
      <Contact />
    </PageLayout>
  );
};

export default Home;
