"use client";
import { useState } from "react";

interface ModalProps {
  isOpen?: boolean;
  onClose: () => void;
  children: React.ReactElement;
}

const Modal: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
}) => {
  const [showModal, setShowModal] = useState(isOpen);

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <div className="backdrop flex justify-center ">
        <div
          className="backdrop backdrop-blur-[8px] backdrop-filter inset-0"
          style={{
            zIndex: -1,
            background: "rgba(0,0,0,0.3)",
          }}
          onClick={onClose}
        ></div>
        <Backdrop click={onClose} />
        <div className="flex items-center justify-center z-50 w-full md:px-0 px-[16px]">
          <div
            className={`md:w-[800px] w-full bg-white rounded-[16px] shadow  modal-content `}
          >
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

const Backdrop = (props: any) => (
  <div onClick={props.click} className="backdrop cursor-pointer">
    <style>{`
      .backdrop {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(0, 0, 0, 0.01);
        z-index: 40;
      }
    `}</style>
  </div>
);

export default Modal;
