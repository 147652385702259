import React, { useState } from "react";
import pricingIcon from "../../assets/img/pricing-icon.svg";

const Pricing = () => {
  const [activeTab, setActiveTab] = useState(0);
  const [active, setActive] = useState(1);
  const tabs = [
    {
      title: "Primary",
      items: [
        {
          id: 1,
          name: "1 month package",
          desc: "Subscribe to get access all primary grade courses ",
        },
        {
          id: 2,
          name: "3 months package",
          desc: "Subscribe to get access all primary grade courses ",
        },
        {
          id: 3,
          name: "1 year package",
          desc: "Subscribe to get access all primary grade courses ",
        },
      ],
    },
    {
      title: "Secondary",
      items: [
        {
          id: 1,
          name: "1 month package",
          desc: "Subscribe to get access all primary grade courses ",
        },
        {
          id: 2,
          name: "3 months package",
          desc: "Subscribe to get access all primary grade courses ",
        },
        {
          id: 3,
          name: "1 year package",
          desc: "Subscribe to get access all primary grade courses ",
        },
      ],
    },
    {
      title: "Tertiary",
      items: [
        {
          id: 1,
          name: "1 month package",
          desc: "Subscribe to get access all primary grade courses ",
        },
        {
          id: 2,
          name: "3 months package",
          desc: "Subscribe to get access all primary grade courses ",
        },
        {
          id: 3,
          name: "1 year package",
          desc: "Subscribe to get access all primary grade courses ",
        },
      ],
    },
  ];

  const handleTabClick = (index: any) => {
    setActiveTab(index);
  };

  return (
    <section className="parallax bg-pricing md:h-screen h-full">
      <div className="w-full mx-auto md:px-[96px] px-[16px] flex h-full overflow-hidden md:py-[170px] py-[140px]">
        <div className="w-full flex flex-col items-center ">
          <h3 className="text-[#334776] md:text-[48px] text-[36px] font-medium text-center font-sans leading-[120%]">
            Pricing plan for every grade
          </h3>
          <div className="pt-[48px] flex items-center justify-center gap-[12px] md:gap-[22px] w-full">
            {tabs?.map((tab, index) => (
              <div
                key={index}
                className={`rounded-[200px] md:px-[42px] px-[18px] flex items-center justify-center  max-h-[48px] cursor-pointer md:w-[150px] w-full md:text-[18px] text-base py-[16px] font-sansregular shadow ${
                  activeTab === index
                    ? "bg-[#EF767A] font-medium  text-white"
                    : "border-[2px] border-[#334776] font-medium text-[#2E394C]"
                }`}
                onClick={() => handleTabClick(index)}
              >
                {tab?.title}
              </div>
            ))}
          </div>
          <div className="md:mt-[96px] mt-[48px] w-full flex flex-col md:flex-row items-center gap-[28px] md:h-[300px] h-full">
            {tabs[activeTab]?.items?.map((item, index) => {
              return (
                <div
                  key={index}
                  onClick={() => setActive(item?.id)}
                  className={`p-[32px] h-full w-full rounded-tr-[48px] rounded-bl-[48px] rounded-tl-[16px] cursor-pointer rounded-br-[16px] border border-[#23F0C7] ${
                    active === item.id ? `bg-[#10EDC1]` : `bg-[#CCFBF1]`
                  } `}
                >
                  <img
                    src={pricingIcon}
                    className="w-[49px] h-[51px] pointer-events-none"
                    alt={item.name}
                  />
                  <h3 className="text-[28px] text-[#120D31] leading-[150%] font-medium font-sans py-[16px]">
                    {item.name}
                  </h3>
                  <p className="text-[18px] text-[#120D31] leading-[150%] font-normal font-sansregular">
                    {item.desc}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing;
